.containerEnlaceLinkTree {
    border: 1px solid black!important;
    margin: 10px 0!important;
}

@media (max-width: 767.98px) {
    #containerLinkTree {
        padding: 40px 0!important;
    }
    #imageLinkTree {
        margin: 20px 0;
    }
    #tituloLinkTree {
        font-size: 60px!important;
        text-align: center;
        margin-top: 20px;
    }
}